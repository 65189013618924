import { nativeAppInterface } from 'src/native_app_interface';

window.nativeAppInterface = nativeAppInterface;

document.addEventListener('turbo:frame-load', function(event){
  //console.log("TURBO FRAME LOAD: ", event)
  if (event.target.id === 'priorityOverlay') {
    // Remove flag telling others an overlay load is in progress.
    $(event.target).removeClass('loading');
  }
});

window.addEventListener('turbo:before-fetch-request', function(event) {
  //console.log("Turbo before-fetch-request event");
  event.preventDefault();
  event.detail.fetchOptions.credentials = 'include';
  event.detail.resume();
})

window.addEventListener('turbo:before-fetch-response', function(event) {
  if (event.detail.fetchResponse.response.ok == false) {
    $(event.target).html('<H3>Turbo Frame fetch error</H3><br><div>' + event.detail.fetchResponse.response.statusText + '</div>')
  } else if (event.detail.fetchResponse.response.redirected) {
    // We were redirected.  Probably to the login screen.  Reload our current page.  If we need to login, the login page will
    // appear.  If not, we were redirected to somewhere we didnt want to be.
    window.location.reload();
  }
  //console.log("Turbo before-fetch-response event", event);
})

/*
 THESE ARE ADDITIONAL TURBO EVENTS.  Keeping them commented out instead of deleting
 them in case we need to debug something in the future.
*/
/*
document.addEventListener('turbo:frame-error', function(e){
  console.log("TURBO FRAME ERROR: ", e)
});

window.addEventListener('turbo:frame-render', function(event) {
  console.log("Turbo frame-render event", event);
})

window.addEventListener('turbo:submit-start', function(event) {
  console.log("Turbo submit-start event: ", event);
})

window.addEventListener('turbo:submit-end', function(event) {
  console.log("Turbo submit-end event: ", event);
})

window.addEventListener('turbo:before-cache', function(event) {
  console.log("Turbo before-cache event");
})

window.addEventListener('turbo:before-render', function(event) {
  console.log("Turbo before-render event");
})

window.addEventListener('turbo:before-stream-render', function(event) {
  console.log("Turbo before-stream-render event");
})

window.addEventListener('turbo:render', function(event) {
  console.log("Turbo render event");
})

window.addEventListener('turbo:load', function(event) {
  console.log("Turbo load event");
})

window.addEventListener('turbo:load-error', function(event) {
  console.log("Turbo load error: ", event);
})
window.addEventListener('turbo:before-frame-render', function() {
  console.log("Turbo before-frame-render event");
})

window.addEventListener('turbo:frame-missing', function() {
  console.log("Turbo frame-missing event");
})

window.addEventListener('turbo:fetch-request-error', function() {
  console.log("Turbo fetch-request-error event");
})

*/
